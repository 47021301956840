import React from "react";
import "./Footer.scss";

const Footer: React.FC = () => {
  return (
    <>
      <div className={"home_slider"}>
        <p>Scroll in or zoom out for more locations!</p>
      </div>
      <div className={"footer"}>
        <h2>© Djordje Janjic</h2>
      </div>
    </>
  );
};

export default Footer;

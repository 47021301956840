import React, { ChangeEvent, useEffect, useState } from "react";
import "./ControlRoom.scss";
import axios from "axios";
import { BASE_URL } from "../../common/url/urls";

const ControlRoom: React.FC = (props) => {
  let files: FileList;
  let selectedGalleryId = -1;

  const [galleries, setGalleries] = useState([]);

  function onSelectedImages(event: ChangeEvent<HTMLInputElement>): void {
    if (!event.target.files) {
      alert("You must select some images!");
      return;
    }
    files = event.target.files;
  }

  async function uploadImages(event: any) {
    console.log(files, selectedGalleryId);
    if (!files || selectedGalleryId === -1) {
      alert("You must select some images and gallery!");
      return;
    }

    const filePaths: string[] = [];
    for (let i = 0; i < files.length; i++) {
      filePaths.push(files.item(i)!.name);
    }
    console.log(filePaths, selectedGalleryId);
    const response = await axios.post(BASE_URL + "/image", {
      filePaths,
      selectedGalleryId,
    });
    console.log(response, "res");
  }

  async function getGalleries() {
    const response = await axios.get(BASE_URL + "/gallery");
    const options = response.data.map((el: any) => {
      return {
        value: el.id,
        label: el.name,
      };
    });
    setGalleries(options);
    if (options.length) {
      selectedGalleryId = options[0].value;
    }
  }

  function selectGallery(event: any) {
    selectedGalleryId = event.target.value;
  }

  useEffect(() => {
    getGalleries();
  }, []);

  return (
    <div className={"control-room-container"}>
      <p>Select images</p>
      <input type="file" multiple={true} onChange={onSelectedImages}></input>
      <select name="gallery" id="gallery" onChange={selectGallery}>
        {galleries.map((el: any, i: number) => (
          <option key={i + "_" + el.value} value={el.value}>
            {el.label}
          </option>
        ))}
      </select>
      <button onClick={uploadImages}>Upload</button>
    </div>
  );
};

export default ControlRoom;

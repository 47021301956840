import React from "react";
import Header from "./components/UI/Header";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import { Route, Routes } from "react-router-dom";
import CustomGallery from "./components/Gallery/CustomGallery";
import Equipment from "./components/Equipment/Equipment";
import ControlRoom from "./components/ControlRoom/ControlRoom";

const App: React.FC = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route path={"/gallery/:galleryId"} element={<CustomGallery />} />
        <Route path={"/equipment"} element={<Equipment />} />
        <Route path={"/control-room"} element={<ControlRoom />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
